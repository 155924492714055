const campaignBaseurl = "http://np-generic-api-alb-1048176293.us-west-2.elb.amazonaws.com/np/v1/campaign/";
const newCampaignCreationUrl = campaignBaseurl+"campaign";
const filterCampaigns = campaignBaseurl + "filtercampaigns";
const getContent = campaignBaseurl + "content";

export {
  filterCampaigns,
  newCampaignCreationUrl,
  getContent
}
