import * as C from '../constants';

const initialState = {
    items: []
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
      case C.SAVE_AUTH_DATA:
          console.log("here SAVE AUTHD TATA", action);
          localStorage.setItem("JWT_TOKEN", action.payload.data.userData.signInUserSession.accessToken.jwtToken);
          return {
            ...state,
            items: action.payload.data
          };
      default:
        return state;
    }
  }