import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import LandingPage from './LandingPage';

export default function Root({store, history}) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LandingPage/>
      </ConnectedRouter>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};