// import { withRouter } from "react-router-dom";
import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { toCapitalize } from '../../utils/utils';
import NoData from '../CommonComponents/NoData';
import Spinner from '../CommonComponents/Spinner';
import Analytics from '../Campaigns/Analytics';
import {
  Table,
  Button,
  Icon,
  Tooltip,
  message,
  Input,
  Menu,
  Row,
  Col,
  Pagination,
  Checkbox,
  notification,
  Dropdown,
  Popconfirm,
  Modal,
  Divider
} from 'antd'

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteCampaignUsingAxios } from '../../actions/deleteCampaignActions';

class CampaignsTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectAll: false,
      selection: [],
      showAnalytics: false,
      pagination: {
        current: 1,
        pageSize: 10,
      },
      showCampaignDetails: false,
      campaignModalData: {},
      campaignsInfo: null,
    }
  }
  onChangeTable(pagination){
    console.log("onChangeTable", pagination)
  }
  onCampaignNameClick(e, row){
    this.setState({
      showCampaignDetails: true,
      campaignModalData: row
    })
  }
  handleCampaignNameClick(e){   
    e.preventDefault();
    this.setState({
      showAnalytics : true
    })
  }

  componentDidMount(){
    var serverData = this.props.items.data;
    if (serverData) {
     this.setState({ 
       campaignsInfo: serverData.campaigns 
      })
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.items.data != prevProps.items.data){
      var serverData = this.props.items.data;
      if (serverData) {
       this.setState({ 
         campaignsInfo: serverData.campaigns
        })
      }
    }
  }

  toggleSelection = (key) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  };
  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        selection.push(`select-${item._original.campaign_name}`);
      });
    }
    this.setState({ selectAll, selection });
  };

  rowFn = (state, rowInfo) => {
    const { selection } = this.state;

    return {
      onClick: (e, handleOriginal) => {
        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {
        background:
          rowInfo &&
          selection.includes(`select-${rowInfo.original.campaign_name}`) &&
          "lightgreen"
      }
    };
  };

  isSelected = key => {
    return this.state.selection.includes(`select-${key}`);
  };
  handleAnalytics(){
    this.setState({
      showAnalytics: false
    })
  }
  onHandleEditCampaign(e, item){
    this.props.onEditCampaign(item);
  }
  onHandleDeleteCampaign(e){
    this.props.dispatch(deleteCampaignUsingAxios(e));
  }
  render(){
    const columns = [
      { title: "ID", dataIndex: "id", key: "id", className: "rt-td-column text-center", minWidth: 350},
      { title: "Name", dataIndex: "name", key: "name", className: "rt-td-column text-center", minWidth: 350, render:(text, row, index)=>{
        return <a style={{color: '#1890ff'}} onClick={(e)=>this.onCampaignNameClick(e, row)}>{text}</a>
      }},
      { title: "Description", dataIndex: "description", key: "description", className: "rt-td-column text-center", minWidth: 350},
      { title: "Source", dataIndex: "source", key: "source", className: "rt-td-column text-center", minWidth: 350},
      { title: "Channel", dataIndex: "channel", key: "channel", className: "rt-td-column text-center", minWidth: 350},
      // { title: "TestMode", dataIndex: "testMode", key: "testMode", className: "rt-td-column text-center", minWidth: 350},
      { title: "Status", dataIndex: "status", key: "status", className: "rt-td-column text-center", minWidth: 350},
      // { title: "MasterStatus", dataIndex: "masterStatus", key: "masterStatus", className: "rt-td-column text-center", minWidth: 350},
      { title: "Scheduledtime", dataIndex: "scheduledtime", key: "scheduledtime", className: "rt-td-column text-center", minWidth: 350},
      // { title: "Schedule", dataIndex: "schedule", key: "schedule", className: "rt-td-column text-center", minWidth: 350},
      // { title: "AbTestCondition", dataIndex: "abTestCondition", key: "abTestCondition", className: "rt-td-column text-center", minWidth: 350},
      // { title: "Updatedby", dataIndex: "updatedby", key: "updatedby", className: "rt-td-column text-center", minWidth: 350},
      { title: "Updatetime", dataIndex: "updatetime", key: "updatetime", className: "rt-td-column text-center", minWidth: 350},
      {title: 'Action',
        key: 'action',
        className: 'noWrap campaign-action-wrapper',
        render: (text, item, index) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <a onClick={(e)=> this.onHandleEditCampaign(e, item)}><EditOutlined/></a>
              <Divider style={{'backgroundColor':'blue'}} type="vertical"/>
              <Popconfirm title="Sure to delete?" onConfirm={() => this.onHandleDeleteCampaign(item)}>
                <DeleteOutlined />
              </Popconfirm>
            </div>
          )
        }
      }
    ]
    const { pagination } = this.state;
    const { loading } = this.props;
    return(
      <>
        <div className="main-table">
          <Table
            // rowKey={id}
            rowClassName={() => 'listRow'}
            // rowSelection={rowSelection}
            className='campaign-list'
            columns={columns}
            dataSource={this.state.campaignsInfo}
            pagination={pagination}
            loading={loading}
            onChange={this.onChangeTable}
            pagination={{ pageSize: 100}}
          />
          <Modal
            title="Campaign Details"
            centered
            visible={this.state.showCampaignDetails}
            onOk={() => this.setState({showCampaignDetails: false})}
            onCancel={() => this.setState({showCampaignDetails: false})}
            width={1000}
          >
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                ID
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.id}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Name
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.name}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Description
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.description}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Carrier
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.carrier}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Source
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.source}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Channel
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.channel}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Status
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.status}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Scheduled Time
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.scheduledtime}
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span='4' style={{color: '#1890ff'}}>
                Updated By
              </Col>
              <Col span='20'>
                {this.state.campaignModalData.updatedby}
              </Col>
            </Row>
          </Modal>
        </div>
        {this.state.showAnalytics &&
          <Analytics show={this.state.showAnalytics} showAnalytics={(e)=> this.handleAnalytics(e)}/>
        }
      </>
    )
  }
}
CampaignsTable.propTypes = {
  loading: PropTypes.bool,
  showAnalytics: PropTypes.any
}
CampaignsTable.defaultProps ={
  pages: 10
}

CampaignsTable.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.any,
  error: PropTypes.bool,
  dispatch: PropTypes.func,
}

function mapStateToProps(state){
	return {
    loading: state.filterCampaign.loading,
    error: state.filterCampaign.error,
    items: state.filterCampaign.items
	}
}

export default connect(mapStateToProps)(CampaignsTable);
