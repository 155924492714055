import React from 'react';
import PropTypes from 'prop-types';
import H2 from '../../CommonComponents/H2';
import IMG from '../../CommonComponents/IMG';

export default function CampaignType({type, icon}){
  let width= "60px", height="60px";
  if(type == "SMS Campaign" || type == "Email Campaign"){
    width = "35px";
  }
  return(
    <div className='campaign-type-container' role="text">
      <div className="row">
        <div className='img-holder col-xs-6 col-sm-4 col-md-2' aria-hidden="true">
          <IMG width={width} height={height} src={icon}/>
        </div>
        <div className='text-container col-xs-6 col-sm-8 col-md-10' aria-hidden="true">
          <div className="row">
            <H2>{type}</H2>
          </div>
          <div className="row">
            Choose this option to create only {type}
          </div>
        </div>
      </div>  
    </div>
  )
}

CampaignType.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.any
}
