/* eslint-disable */
import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import MySelect from './MySelect';
import {createNewCampaignUsingAxios} from '../../actions/createNewCampaignActions';
import Dropzone from 'react-dropzone';
import DateTimePicker from 'react-datetime-picker';

const CampaignTypeOptions = [
  {value: "Executable", label: "Executable"},
  {value: "Schedule", label: "Schedule"},
]

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    campaignName: Yup.string()
      .min(1, 'Please enter campaign name')
      .required('Please enter campaign name'),
    description: Yup.string()
      .min(1, 'Please enter description')
      .required('Please enter description'),
    campaignType: Yup.string()
      .min(1, "Pick at least 1 campaign type")
      .required("Pick at least 1 campaign type"),
    segmentFile: Yup.string()
      .min(1, "Upload Segment File")
      .required("Upload Segment File"),
    contentFile: Yup.string()
      .min(1, "Upload Content File")
      .required("Upload Content File"),
    testMode: Yup.string()
      .min(1, 'Please enter campaign name')
      .required('Please enter campaign name'),
  }),
  mapPropsToValues: props => ({
    campaignName: "",
    description: "",
    campaignType: "",
    segmentFile: "",
    contentFile: "",
    testMode: false,
    scheduledDate: ""
  }),
  handleSubmit: (values, { props }) => {
    const payload = {
      ...values,
      campaignName: values.campaignName,
      description: values.description,
      campaignType: values.campaignType,
      segmentFile: values.segmentFile,
      contentFile: values.contentFile,
      testMode: values.testMode,
      typeOfCampaign: props.typeOfCampaign,
      scheduledDate: values.scheduledDate
    };
    props.dispatch(createNewCampaignUsingAxios(payload, props));
  },
  displayName: "MyForm"
});

const DisplayFormikState = props =>
  <div style={{ margin: '1rem 0' }}>
    <h3 style={{ fontFamily: 'monospace' }} />
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> ={' '}
      {JSON.stringify(props, null, 2)}
    </pre>
  </div>;

const MyForm = props => {
  const {
    values,
    touched,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  } = props;
  const [segmentFile, setSegmentFile] = useState([]);
  const [contentFile, setContentFile] = useState([]);
  const [scheduledDate, setScheduledDate] = useState(new Date())
  function onUploadSegmentFile(file){
    setFieldValue("segmentFile", file[0])
    setFieldTouched("segmentFile", true)
    setSegmentFile(file)
  }
  function onUploadContentFile(file){
    setFieldValue("contentFile", file[0])
    setFieldTouched("contentFile", true)
    setContentFile(file)
  }
  function handleSelect(date){
    setFieldValue("scheduledDate", date)
    setFieldTouched("scheduledDate", true)
    setScheduledDate(date);
  }
  return (
    <form onSubmit={handleSubmit} className="new-campaign-form mb-5">
      <div className="row new-campaign-container">
        <div className="col-md-12 col-lg-6">
          <label htmlFor="campaignName" className="required" style={{ display: "block" }}>
            Campaign Name
          </label>
          <input
            id="campaignName"
            placeholder="Enter your campaign name"
            type="text"
            value={values.campaignName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.campaignName && touched.campaignName && (
            <div style={{ color: "red", marginTop: ".5rem" }}>{errors.campaignName}</div>
          )}
        </div>
        <div className="col-md-12 col-lg-6">
          <label htmlFor="campaignName" className="required" style={{ display: "block" }}>
            Description
          </label>
          <input
            id="description"
            placeholder="Enter description"
            type="text"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.description}
          />
           {errors.description && touched.description && (
            <div style={{ color: "red", marginTop: ".5rem" }}>{errors.description}</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div style={{ margin: "1rem 0" }}>
            <label htmlFor="color" className="required">Upload Segment File</label>
            <Dropzone onDrop={onUploadSegmentFile} multiple={false} tag="segmentFile" onBlur={setFieldTouched}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="file-upload-container" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {segmentFile.length > 0 ? <span>Uploaded {segmentFile[0].path.length < 50 ? segmentFile[0].path : segmentFile[0].path.slice(0, 49) + ' ... '} file </span>: <span className="text-muted">Drag 'n' drop some files here, or click to select files</span>}
                  </div>
                </section>
              )}
            </Dropzone>
            {errors.segmentFile && touched.segmentFile && (
              <div style={{ color: "red", marginTop: ".5rem" }}>{errors.segmentFile}</div>
            )}
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div style={{ margin: "1rem 0" }}>
            <label htmlFor="color" className="required">Upload Content File</label>
            <Dropzone onDrop={onUploadContentFile} multiple={false} tag="contentFile" onBlur={setFieldTouched}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className="file-upload-container" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {contentFile.length > 0 ? <span>Uploaded {contentFile[0].path.length < 50 ? contentFile[0].path : contentFile[0].path.slice(0, 49) + ' ... '} file </span>: <span className="text-muted">Drag 'n' drop some files here, or click to select files</span>}
                  </div>
                </section>
              )}
            </Dropzone>
            {errors.contentFile && touched.contentFile && (
              <div style={{ color: "red", marginTop: ".5rem" }}>{errors.contentFile}</div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <MySelect
            value={values.campaignType}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            error={errors.campaignType}
            touched={touched.campaignType}
            label='Select Campaign Type'
            tag="campaignType"
            options={CampaignTypeOptions}
          />
          </div>
          <div className="col-md-12 col-lg-6 react-datetime-container">
            {values.campaignType.value == 'Schedule' &&
              <DateTimePicker
                id="scheduledDate"
                tag="scheduledDate"
                onChange={handleSelect}
                value={scheduledDate}
              />
            }
        </div>
      </div>
      <div className="row container">
        <label>
        <input
            id="testMode"
            type="checkbox"
            value={values.testMode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <span className="ml-2">Dry Run</span>
        </label>
      </div>
      <button
        type="button"
        className="outline"
        onClick={handleReset}
        disabled={!dirty || isSubmitting}
      >
        Reset
      </button>
      <button type="submit" disabled={isSubmitting}>
        Submit
      </button>
      {/* <DisplayFormikState {...props} /> */}
    </form>
  );
};

const MyEnhancedForm = formikEnhancer(MyForm);


export default connect()(withRouter(formikEnhancer(MyForm)));
