import React, { Component } from 'react';

export default class TextEditor extends Component{
    constructor(props) {
      super(props);
      this.state = {
        content: ''
      };
    }

    render() {
        console.log("props template content==>", this.props);
        return (
          <React.Fragment>
              <div dangerouslySetInnerHTML={{ __html: this.props.s3Data }} className="template-content-container"></div>
          </React.Fragment>
        )
     }
  }