import React, { Component } from 'react';
import { Button } from 'antd';
import { Auth } from 'aws-amplify';

export default class CSignOut extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    localStorage.removeItem('JWT_TOKEN');
    console.log("signout")
    Auth.signOut();
    window.location.reload();
    console.log("after reload");
  }

  render() {
    return (
      <Button light outline sm border="0" onClick={this.signOut}>Sign Out</Button>
    )
  }
}