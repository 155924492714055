import * as C from '../constants';
import { createNewCampaign } from '../services';

export const fetchDataBegin = () => {
  return ({
    type: C.CREATE_NEW_CAMPAIGN_BEGIN
  })
};

export const fetchDataSuccess = data  =>{
  return ({
    type: C.CREATE_NEW_CAMPAIGN_SUCCESS,
    payload: { 
      data 
    }
  })
};

export const fetchDataFailure = error => {
  return ({
    type: C.CREATE_NEW_CAMPAIGN_FAILURE,
    payload: { error }
  })
};

export function createNewCampaignUsingAxios(props, prop) {
    return (dispatch) => {
        dispatch(fetchDataBegin());
        createNewCampaign(props)
          .then( response =>{
                prop.history.push('/Campaigns');
                dispatch(fetchDataSuccess(response))
            })
          .catch(error => {
            dispatch(fetchDataFailure(error));
          })
    }
}
