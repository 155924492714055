import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Campaigns from './Campaigns';
import NewCampaign from './Campaigns/NewCampaign';
import EmailCampaign from './Campaigns/EmailCampaign';
import SMSCampaign from './Campaigns/SMSCampaign';
import NewUser from './Campaigns/NewUser';
import Analytics from './Campaigns/Analytics';
import Triggers from './Triggers';
import Templates from './Templates';
import Chat from './Chat';
import DesignStudio from './DesignStudio';
import WhatsApp from './WhatsApp';
import WhatsAppChat from './WhatsAppChat';


const Routes = () => (
  <Switch>
    <Route exact path="/" component={Campaigns} />
    <Route exact path="/Campaigns" component={Campaigns} />
    <Route exact path="/Campaigns/NewCampaign" component={NewCampaign} />
    <Route exact path="/Campaigns/EmailCampaign" component={EmailCampaign} />
    <Route exact path="/Campaigns/SMSCampaign" component={SMSCampaign} />
    <Route exact path="/Campaigns/NewUser" component={NewUser} />
    <Route exact path="/Campaigns/Analytics" component={Analytics} />
    <Route exact path="/Triggers" component={Triggers} />
    <Route exact path="/Templates" component={Templates} />
    <Route exact path="/Chat" component={Chat}/>
    <Route exact path="/DesignStudio/:id" render={(props) => <DesignStudio {...props} />}/>
    <Route exact path="/DesignStudio" component={DesignStudio} />
    <Route exact path="/WhatsApp" component={WhatsApp} />
    <Route exact path="/WhatsAppChat" component={WhatsAppChat} />
    
  </Switch>
);

export default Routes;