import React, { Component } from 'react'

import './App.css'
import './index.css'

import Amplify, { Auth } from 'aws-amplify'
import awsmobile from '../..//aws-exports-whatsapp'
import { withAuthenticator } from 'aws-amplify-react'
import AWSAppSyncClient from 'aws-appsync'
import { Rehydrated } from 'aws-appsync-react'

import { ApolloProvider } from 'react-apollo'
import { ChatAppWithData } from './ChatApp'

Amplify.configure(awsmobile)

const client_new = new AWSAppSyncClient({
  url: "https://api.eiskot.com/np/v1/graphql",
  region: awsmobile.aws_appsync_region,
  auth: {
    type: awsmobile.aws_appsync_authenticationType,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken()
  },
  offlineConfig: {
    keyPrefix:"jamna",
    callback: (err, succ) => {
      if (err) {
        const { mutation, variables } = err;

        console.warn(`ERROR for ${mutation}`, err);
      } else {
        const { mutation, variables } = succ;

        console.info(`SUCCESS for ${mutation}`, succ);
      }
    },
  },
  complexObjectsCredentials: () => Auth.currentCredentials()
})

class WhatsAppChat extends Component {
  state = { session: null }

  async componentDidMount() {
    const session = await Auth.currentSession()
    this.setState({ session })
  }

  userInfo = () => {
    const session = this.state.session
    if (!session) {
      return {}
    }
    const payload = session.idToken.payload
    return { name: payload['cognito:username'], id: payload['sub'] }
  }

  render() {
    return <ChatAppWithData {...this.userInfo()} />
  }
}

const WithProvider = () => (
  <ApolloProvider client={client_new}>
    <Rehydrated>
      <WhatsAppChat />
    </Rehydrated>
  </ApolloProvider>
)

export default withAuthenticator(WithProvider)
