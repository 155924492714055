import * as C from '../constants';
import { getSegmentationSources } from '../services';

export const fetchDataBegin = () => {
  return ({
    type: C.FETCH_SEGMENTATION_SOURCES_BEGIN
  })
};

export const fetchDataSuccess = data  =>{
  return ({
    type: C.FETCH_SEGMENTATION_SOURCES_SUCCESS,
    payload: { 
      data 
    }
  })
};

export const fetchDataFailure = error => {
  return ({
    type: C.FETCH_SEGMENTATION_SOURCES_FAILURE,
    payload: { error }
  })
};

export function fetchSegmentationSourcesUsingAxios() {
  return (dispatch) => {
    dispatch(fetchDataBegin());
    getSegmentationSources()
      .then( response =>{
            dispatch(fetchDataSuccess(response))
        })
      .catch(error => {
        dispatch(fetchDataFailure(error));
      })
  }
}
