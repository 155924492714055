import React, { useEffect, useState } from 'react';
import { Select, Button, Input, Row, Col, Upload, Card, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;

const col1 = 4
const col2 = 20

export default function Triggers () {
  console.log("templates")
  const [triggerType, setTriggerType] = useState();
  const [disableCsvAddButton, setDisableCsvAddButton] = useState(true);
  const [csvSegFileName, setCsvSegFileName] = useState();
  const [csvSegDesc, setCsvSegDesc] = useState();
  const [csvFileUpload, setCsvFileUpload] = useState(false)

  const [disableRdsAddButton, setDisableRdsAddButton] = useState(true);
  const [rdsHost, setRdsHost] = useState();
  const [rdsName, setRdsName] = useState();
  const [rdsUserName, setRdsUserName] = useState()
  const [rdsPort, setRdsPort] = useState();
  const [rdsPassword, setRdsPassword] = useState();
  const [rdsQuery, setRdsQuery] = useState()

  const [disableApiAddButton, setDisableApiAddButton] = useState(true);
  const [apiName, setApiName] = useState();
  const [apiDescription, setApiDescription] = useState();
  const [apiUrl, setApiUrl] = useState()

  const [disableSqsAddButton, setDisableSqsAddButton] = useState(true);
  const [sqsQueueUrl, setSqsQueueUrl] = useState();
  const [sqsAccessKey, setSqsAccesskey] = useState();
  const [sqsAccessSecret, setSqsAccessSecret] = useState()

  const [disableKafkaAddButton, setDisableKafkaAddButton] = useState(true);
  const [topicUrl, setTopicUrl] = useState();
  const [kafkaAccessKey, setKafkaAccesskey] = useState();
  const [kafkaAccessSecret, setKafkaAccessSecret] = useState()

  useEffect(()=> {
    if(topicUrl && kafkaAccessKey && kafkaAccessSecret){
      setDisableKafkaAddButton(false)
    } else {
      setDisableKafkaAddButton(true)
    }
  }, [topicUrl, kafkaAccessKey, kafkaAccessSecret])

  function onKafkaDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'topicUrl'){
      setTopicUrl(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'kafkaAccessKey'){
      setKafkaAccesskey(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'kafkaAccessSecret'){
      setKafkaAccessSecret(e.target.value)
    }
  }

  useEffect(()=> {
    if(sqsQueueUrl && sqsAccessKey && sqsAccessSecret){
      setDisableSqsAddButton(false)
    } else {
      setDisableSqsAddButton(true)
    }
  }, [sqsQueueUrl, sqsAccessKey, sqsAccessSecret])

  function onSqsDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'sqsQueueUrl'){
      setSqsQueueUrl(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'sqsAccessKey'){
      setSqsAccesskey(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'sqsAccessSecret'){
      setSqsAccessSecret(e.target.value)
    }
  }

  function onRdsDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'rdsHost'){
      setRdsHost(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsName'){
      setRdsName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsUserName'){
      setRdsUserName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsPort'){
      setRdsPort(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsPassword'){
      setRdsPassword(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsQuery'){
      setRdsQuery(e.target.value)
    } 
  }

  useEffect(()=> {
    if(apiName && apiDescription && apiUrl){
      setDisableApiAddButton(false)
    } else {
      setDisableApiAddButton(true)
    }
  }, [apiName, apiDescription, apiUrl])

  function onApiDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'apiName'){
      setApiName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'apiDescription'){
      setApiDescription(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'apiUrl'){
      setApiUrl(e.target.value)
    }
  }

  function onRdsDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'rdsHost'){
      setRdsHost(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsName'){
      setRdsName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsUserName'){
      setRdsUserName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsPort'){
      setRdsPort(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsPassword'){
      setRdsPassword(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsQuery'){
      setRdsQuery(e.target.value)
    } 
  }

  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      console.log("info",info)
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);

      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(()=> {
    if(csvSegFileName && csvSegDesc && csvFileUpload){
      setDisableCsvAddButton(false)
    } else {
      setDisableCsvAddButton(true)
    }
  }, [csvSegFileName, csvSegDesc, csvFileUpload])

  function handleTriggerTypeSelection(e){
    setTriggerType(e)
  }
  function onCsvAddClick(){
    console.log("onCsvAddClick")
  }
  function onRdsAddClick(){

  }
  function onApiAddClick(){

  }
  function onSqsAddClick(){

  }
  function onKafkaAddClick(){

  }
  function onCsvDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'csvSegFileName'){
      setCsvSegFileName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'csvSegDesc'){
      setCsvSegDesc(e.target.value)
    } else if(e.file && e.file.status=="done"){
      setCsvFileUpload(true)
    }
  }

  useEffect(()=> {
    if(rdsHost && rdsName && rdsUserName && rdsPort && rdsPassword && rdsQuery){
      setDisableRdsAddButton(false)
    } else {
      setDisableRdsAddButton(true)
    }
  }, [rdsHost, rdsName, rdsUserName, rdsPort, rdsPassword, rdsQuery])

  function onRdsDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'rdsHost'){
      setRdsHost(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsName'){
      setRdsName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsUserName'){
      setRdsUserName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsPort'){
      setRdsPort(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsPassword'){
      setRdsPassword(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'rdsQuery'){
      setRdsQuery(e.target.value)
    } 
  }

  function renderTriggerContent(){
    console.log("renderTriggerContent", triggerType)
    switch(triggerType){
      case 'csv':
        return (
          <div>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Segmentation File Name <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter name" data-name="csvSegFileName" onChange={onCsvDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Segmentation Description <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter description" data-name="csvSegDesc" onChange={onCsvDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                  <div>Segmentation File <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
                </Col>
              <Col>
                <Upload {...props} onChange={onCsvDataChange} data-name="csvUpload">
                  <Button icon={<UploadOutlined />}>Choose Files</Button>
                </Upload>
              </Col>
            </Row>
            <Row>
              <Button type="primary" disabled={disableCsvAddButton} style={{ marginRight: '8px' }} onClick={onCsvAddClick}>Add</Button>
            </Row>
          </div>
        )
      case 'rds':
        return (
          <div>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Database Host <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter host" data-name="rdsHost" onChange={onRdsDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Database Name <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter name" data-name="rdsName" onChange={onRdsDataChange} /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Database Port <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter port" data-name="rdsPort" onChange={onRdsDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Database Username <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter username" data-name="rdsUserName" onChange={onRdsDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Database password <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter password" data-name="rdsPassword" onChange={onRdsDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Database Query <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><TextArea placeholder="select * from users;" data-name="rdsQuery" onChange={onRdsDataChange}/></Col>
            </Row>
            <Row>
              <Button type="primary" disabled={disableRdsAddButton} style={{ marginRight: '8px' }} onClick={onRdsAddClick}>Add</Button>
            </Row>
          </div>
        )
      case 'api':
        return (
          <div>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>API Name <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter API Name" data-name="apiName" onChange={onApiDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>API Description <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter description" data-name="apiDescription" onChange={onApiDataChange} /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>API Url <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter port" data-name="apiUrl" onChange={onApiDataChange}/></Col>
            </Row>
            
            <Row>
              <Button type="primary" disabled={disableApiAddButton} style={{ marginRight: '8px' }} onClick={onApiAddClick}>Add</Button>
            </Row>
          </div>
        )
      case 'sqs':
        return (
          <div>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Queue Url <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter queue url" data-name="sqsQueueUrl" onChange={onSqsDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>AWS Accesskey <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter access key" data-name="sqsAccessKey" onChange={onSqsDataChange} /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>AWS Accesssecret <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter access secret" data-name="sqsAccessSecret" onChange={onSqsDataChange}/></Col>
            </Row>
            
            <Row>
              <Button type="primary" disabled={disableSqsAddButton} style={{ marginRight: '8px' }} onClick={onSqsAddClick}>Add</Button>
            </Row>
          </div>
        )
      case 'kafka':
        return (
          <div>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Kafka Topic Url <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter topic url" data-name="topicUrl" onChange={onKafkaDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>AWS Accesskey <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter access key" data-name="kafkaAccessKey" onChange={onKafkaDataChange} /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>AWS Accesssecret <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter access secret" data-name="kafkaAccessSecret" onChange={onKafkaDataChange}/></Col>
            </Row>
            
            <Row>
              <Button type="primary" disabled={disableKafkaAddButton} style={{ marginRight: '8px' }} onClick={onKafkaAddClick}>Add</Button>
            </Row>
          </div>
        )
    }

  }
  return (
    <div>
      <div className="container">
        <Card title="Add Source"  style={{ marginTop: '30px' }}>
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Col span={col1}>
              <div>Select the Source <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
            </Col>
            <Col span={col2}>
              <Select placeholder="Please Select" style={{ width: 320 }} onChange={handleTriggerTypeSelection}>
                <Option value="csv">CSV File</Option>
                <Option value="rds">RDS</Option>
                <Option value="api">API</Option>
                <Option value="sqs">SQS</Option>
                <Option value="kafka">KAFKA</Option>
              </Select>
            </Col>
          </Row>
        </Card>
        { triggerType &&
          <Card title="Add Content" style={{marginTop: '30px'}}>
            {renderTriggerContent()}
          </Card>
        }
      </div>
    </div>
  );
};
