import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Upload, Card , message} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const col1 = 4
const col2 = 20

export default function Templates(){
  const [disableAddButton, setDisableAddButton] = useState(true);
  const [templateName, setTemplateName] = useState();
  const [templateDescription, setTemplateDescription] = useState();
  const [templateSubject, setTemplateSubject] = useState();
  const [templateContent, setTemplateContent] = useState();
  const [csvFileUpload, setCsvFileUpload] = useState(false)
  const [csvData, setCsvData] = useState()

  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(()=> {
    if(templateName && templateDescription && templateSubject && templateContent && csvFileUpload){
      setDisableAddButton(false)
    } else {
      setDisableAddButton(true)
    }
  }, [templateName, templateDescription, templateSubject, templateContent, csvFileUpload])

  function onAddClick(){
    console.log("onAddClick")
  }

//   if (e.target.type === "file") {
//     var i;
//     segment[e.target.name]=[];
//     for(i=0;i<e.target.files.length;i++) {
//         segment[e.target.name][i] = e.target.files[i];
      
//     }
// }

  function onTemplateDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'templateName'){
      console.log("here name", e.target.value)
      setTemplateName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'templateDescription'){
      setTemplateDescription(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'templateSubject'){
      setTemplateSubject(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'templateContent'){
      setTemplateContent(e.target.value)
    } else if(e.file && e.file.status=="done"){
      setCsvData(e.fileList)
      setCsvFileUpload(true)
    }
  }

  return (
    <div>
      <div className="container">
        <Card title="Add Content"  style={{marginTop: '30px'}}>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Template Name <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter name" data-name="templateName" onChange={onTemplateDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Template Description <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter description" data-name="templateDescription" onChange={onTemplateDataChange} /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Subject <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter subject" data-name="templateSubject" onChange={onTemplateDataChange} /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Template Content <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter content" data-name="templateContent" onChange={onTemplateDataChange} /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Campaign Contents <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col>
                <Upload {...props} onChange={onTemplateDataChange} data-name="csvUpload">
                  <Button icon={<UploadOutlined />}>Choose Files</Button>
                </Upload>
              </Col>
            </Row>
            <Row>
              <Button type="primary" style={{ marginRight: '8px' }} disabled={disableAddButton} onClick={onAddClick}>Add</Button>
            </Row>
        </Card>
      </div>
    </div>
  );
};
