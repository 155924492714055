import * as C from '../constants';

const initialState = {
  items: [],
  loading: false,
  error: null
};

export default function GetContentReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case C.SAVE_CAMPAIGN_BEGIN:
      return {
        ...state,
        loading: true
      };

    case C.SAVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.data
      };

    case C.SAVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response ? action.payload.error.response.status : true,
        items: []
      };

    default:
      return state;
  }
}
