import React from 'react'
import PropTypes from 'prop-types'
import appsync from './images/engagemently.png'

const ConversationBar = ({ conversation, name, switchView }) => {
  const title = 'Engagemently AI Based Chat' + (name ? ` > ${name}` : '')
  return (
    <div className="topbar">
      <nav className="navbar navbar-expand-lg navbar-light bg-primary">
        <button
          className="navbar-toggler mr-2"
          type="button"
          onClick={switchView}
        >
          <i className="fas fa-chevron-circle-left" />
        </button>
        <span className="navbar-brand"> {title} </span>
        <div className="d-flex flex-row align-items-center ml-auto">
          
	  <img
            src={appsync}
            alt="Engagemently Campaign Management"
            style={{
              width: '7.5em'
            }}
          />
        </div>
      </nav>
    </div>
  )
}
ConversationBar.propTypes = {
  conversation: PropTypes.object,
  name: PropTypes.string,
  switchView: PropTypes.func.isRequired
}

export default ConversationBar
