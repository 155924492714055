import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import NewUser from './NewUser';
// import NewRule from './NewRule';
import { Select, Checkbox, Button, Input, Modal, Row, Col, Collapse, Tabs, Upload, DatePicker, TimePicker, message, Alert } from 'antd';
import moment from "moment";
import { UploadOutlined, InfoCircleFilled } from '@ant-design/icons';
// import { func } from 'prop-types';
const { Option } = Select;
const { Search, TextArea } = Input;
const { Panel } = Collapse;
const { TabPane } = Tabs;
import {fetchCampaignDataUsingAxios} from '../../actions/filterCampaignActions';
import {fetchContentusingAxios} from '../../actions/getContentActions';
import {saveCampaignUsingAxios} from '../../actions/saveCampaignActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSegmentationSourcesUsingAxios } from '../../actions/getSegmentationSources';
import Editor from './WYSIWYGEditor';
import TemplateContent from './TemplateContent';
import UploadZip from './UploadZip';

function Subheader({dispatch, contentData, segmentationSources, editData, reload, saveCampaignData}){
  const [visible, setVisible] = useState(false);
  const [channelType, setChannelType] = useState('SMS')
  const [campaignChannelType, setCampaignChannelType] = useState('ALL')
  const [jsonlContent, setJsonlContent] = useState();
  const [segmentationType, setSegmentationType] = useState('')
  // const [dataBase, setDataBase] = useState('')
  // const [existingTrigger, setExistingTrigger] = useState('')
  const [campaignScheduleType, setCampaignScheduleType] =  useState('Onetime')
  const [campaignStatus, setCampaignStatus] =  useState('ALL')
  const [showHistory, setShowHistory] =  useState(false)

  //new campaign
  const [newCampaignName, setNewCampaignName] = useState();
  const [newCampaignDescription, setNewCampaignDescription] = useState()
  const [newCampaignSubject, setNewCampaignSubject] = useState()
  const [newCampaignUser, setNewCampaignUser] = useState()
  const [newCampaignTestMode, setNewCampaignTestMode] = useState(false)
  const [newCampaignTime, setNewCampaignTime] = useState()
  const [newCampaignCsvContentFile, setNewCampaignCsvContentFile] = useState(null)
  const [newCampaignExistingTemplateId, setNewCampaignExistingTemplateId] = useState()
  const [newCampaignExistingSegmentId, setNewCampaignExistingSegmentId] = useState()
  const [newSegmentationCsvContentFile, setNewSegmentationCsvContentFile] = useState(null)
  const [uploadedZipFileData, setUploadedZipFileData] = useState(null);
  const [campaignContentSelectOptions, setCampaignContentSelectOptions] = useState([]);
  const [campaignContentSelectedTab, setCampaignContentSelectedTab] = useState('');
  const [showFromUserText, setShowFromUserText] = useState(false);
  const [editedCampaignId, setEditedCampaignId] = useState();

  useEffect(()=> {
    let campaignProps = {
      campaignScheduleType: campaignScheduleType,
      campaignChannelType: campaignChannelType,
      campaignStatus: campaignStatus,
      showHistory: showHistory
    }
    dispatch(fetchCampaignDataUsingAxios(campaignProps))

  }, [campaignScheduleType, campaignChannelType, campaignStatus, showHistory])

  useEffect(() => {
    // console.log("saveCampaignData", saveCampaignData)
    resetState()
    reload()
  },[saveCampaignData])

  function resetState(){
    // console.log("resetState")
    setNewCampaignName();
    setNewCampaignDescription();
    setNewCampaignSubject();
    setNewCampaignUser();
    setNewCampaignTestMode();
    setEditedCampaignId();
  }

  useEffect(()=> {
    if(editData){
      setVisible(true)
      setNewCampaignName(editData.name);
      setNewCampaignDescription(editData.description);
      setNewCampaignSubject(editData.subject);
      setNewCampaignUser(editData.username);
      setNewCampaignTestMode(editData.testMode);
      setEditedCampaignId(editData.id);
    }
  }, [editData])

  function onNewCampaignDataChange(e){
    if(e.target && e.target.getAttribute('data-name') == 'campaignName'){
      setNewCampaignName(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'campaignDescription'){
      setNewCampaignDescription(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'campaignSubject'){
      setNewCampaignSubject(e.target.value)
    } else if(e.target && e.target.getAttribute('data-name') == 'campaignUser'){
      setNewCampaignUser(e.target.value)
    } 
  }

  function onSaveCampaign(){
    //set selected file
    let selectedContentFile = null;
    if(campaignContentSelectedTab == 'Upload Contents Tab'){
      selectedContentFile = newCampaignCsvContentFile;
    } else if(campaignContentSelectedTab == 'Select Existing Template Tab'){
      selectedContentFile = newCampaignExistingTemplateId;
    }else if(campaignContentSelectedTab == 'Upload Zip Tab'){
      selectedContentFile = uploadedZipFileData;
    }

    let saveCampaignProps = {
      "channel" : channelType,
      "scheduleType": campaignScheduleType,
      "source": "engagemently",
      "name": newCampaignName,
      "description": newCampaignDescription,
      "subject": newCampaignSubject,
      "from": newCampaignUser,
      "abTestCondition": "",
      "testMode": newCampaignTestMode,
      "properties": {"businessContentId":"test","programId":"test"},
      "scheduledTime" : newCampaignTime || new Date().getTime(),
      "schedule":{"timezoneDefault":"PST","timezonesSelected":["CST","EST","MST","PST"]},
      "updatedBy":"",
      "segmentation":{"type":segmentationType},
      "rdshost":{"host":"","port":5432,"username":"","password":""},
      "contentfile": newCampaignCsvContentFile,
      "content_source": newCampaignExistingTemplateId,
      "segmentation_source": newCampaignExistingSegmentId,
      "selectedTab": campaignContentSelectedTab,
      "selectedContentFile": selectedContentFile,
     }
     if(newSegmentationCsvContentFile){
      saveCampaignProps["csvfile"] = newSegmentationCsvContentFile
     }
    dispatch(saveCampaignUsingAxios(saveCampaignProps))
    setVisible(false)
  }

  function onCampaignContentCsvFileUpload(e){
    console.log('e.target.files[0]', e.target.files[0]);
      setNewCampaignCsvContentFile(e.target.files[0]);
      if (e.target.files[0] && !campaignContentSelectOptions.includes('Upload Contents Tab')) {
        setCampaignContentSelectOptions([...campaignContentSelectOptions, 'Upload Contents Tab']);
      } else if(!e.target.files[0]) {
        let selectOptions = campaignContentSelectOptions;
        const index = selectOptions.indexOf('Upload Contents Tab');
        if (index > -1) {
          selectOptions.splice(index, 1);
        }
        setCampaignContentSelectOptions([...selectOptions]);
      }
      setCampaignContentSelectedTab('Upload Contents Tab');
  }

  function onSegmentationCsvFileUpload(e){
    if(e.file && e.file.status=="done"){
      setNewSegmentationCsvContentFile(e.fileList[0].originFileObj);
    }
  }
  function onFromUserIconClick(){
    console.log('onFromUserIconClick');
    setShowFromUserText(!showFromUserText);
  }
  function onNewCampaignTestModeChange(){
    setNewCampaignTestMode(!newCampaignTestMode)
  }

  function handleChange(){
    // console.log("e", e)
  }
  function handleCampaignScheduleTypeChange(e){
    setCampaignScheduleType(e)
  }
  function handleHistoryChange(){
    setShowHistory(!showHistory)
  }
  function handleCampaignStatusChange(e){
    setCampaignStatus(e)
  }
  function onSearch(){
    // console.log(value);
  }
  function onScheduledTimePickerChange(e){
    setNewCampaignTime(e._d.getTime())
  }
  function onCreateNewCampaignClick(){
    setVisible(true)
    resetState()
    if(channelType == 'SMS'){
      dispatch(fetchContentusingAxios())
    }
  }
  function handleCampaignChannelChange(e){
    setCampaignChannelType(e)
  }
  function onSelectTabChanges(e){
    console.log('onSelectTabChanges==>>', e);
    setCampaignContentSelectedTab(e);
  }
  function setUploadZipPath(e){
    setUploadedZipFileData(e);
    if (e && !campaignContentSelectOptions.includes('Upload Zip Tab')) {
      setCampaignContentSelectOptions([...campaignContentSelectOptions, 'Upload Zip Tab']);
    }
  }
  function handleOnUploadZipReset(){
    console.log('handleOnUploadZipReset');
    let selectOptions = campaignContentSelectOptions;
      const index = selectOptions.indexOf('Upload Zip Tab');
      if (index > -1) {
        selectOptions.splice(index, 1);
      }
      setCampaignContentSelectOptions([...selectOptions]);
  }
  function handleChannelChange(e){
    setChannelType(e)
  }
  function onClickCampaignContents(){
    // console.log("onClickCampaignContents", e)
  }
  function onClickSchedule(){
    // console.log("onClickSchedule", e)
  }
  function onClickSegmentation(){
    // console.log("onClickSegmentation", e)
    dispatch(fetchSegmentationSourcesUsingAxios())
  }
  // function onClickCampaignAttributes(){
  //   // console.log("onClickCampaignAttributes", e)
  // }
  function onCampaignContentsTabChange(){
    // console.log("onCampaignContentsTabChange", e)
  }
  // function onScheduleTabChange(){
  //   // console.log("onScheduleTabChange", e)
  // }
  function onSegmentationTabChange(){
    // console.log("onSegmentationTabChange", e)
  }
  // function onCampaignAttributesTabChanges(){
  //   // console.log("onCampaignAttributesTabChanges", e)
  // }
  function handleCampaignMimeTypeChange(){
    // console.log("handleCampaignMimeTypeChange", e)
  }
  function handleCampaignTemplatesChange(e){
    console.log("handleCampaignTemplatesChange", e)
    setNewCampaignExistingTemplateId(e);
    if (e && !campaignContentSelectOptions.includes('Select Existing Template Tab')) {
      setCampaignContentSelectOptions([...campaignContentSelectOptions, 'Select Existing Template Tab']);
    }
  }
  function handleSegmentationTypeChange(e){
    // console.log("handleSegmentationTypeChange", e)
    setSegmentationType(e)
  }
  function handleSampleJsonOnClick(){
    // console.log("handleSampleJsonOnClick", e)
    let sampleJsonlContent = [{"application_id":"c1b074cj4pp", "mdn":"+17777777777","timezone":"America/Los_Angeles"},
    {"application_id":"c1b074cj4pq", "mdn":"+17777777777","timezone":"America/Los_Angeles"}]
    setJsonlContent(sampleJsonlContent)
  }
  function onSegmentationTypeFieldChange(e){
    // console.log("e", e.target.value)
    setJsonlContent(e.target.value)
  }
  function handleDataBaseSelection(){
    // setDataBase(e)
  }
  function handleExistingTriggerSelection(e){
    // console.log("handleExistingTriggerSelection", e)
    setNewCampaignExistingSegmentId(e)
  }
  const col1 = 4
  const col2 = 20
  const col3=6
  const col4=18
  const uploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      "authorization": 'authorization-text',
      "Access-Control-Allow-Origin": "*"
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  function renderSegmentationTypeSwitch(param){
    // console.log("renderSegmentationTypeSwitch", param);
    // console.log("json length", jsonlContent);
    switch(param) {
      case 'jsonlfile':
        return (
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Col span="6">
              <div>Contents <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
            </Col>
            <Col span="18">
              <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <TextArea name="jsonlcontents" placeholder="jsonl contents = multiple JSON Data Rows separated by Newline Character.
                  {&quot;application_id&quot;:&quot;c1b074cj4pp&quot;, &quot;mdn&quot;:&quot;+17777777777&quot;,&quot;timezone&quot;:&quot;America/Los_Angeles&quot;}
                  {&quot;application_id&quot;:&quot;c1b074cj4pq&quot;, &quot;mdn&quot;:&quot;+17777777777&quot;,&quot;timezone&quot;:&quot;America/Los_Angeles&quot;}" 
                  rows={6} 
                  value={JSON.stringify(jsonlContent)}
                  onChange={onSegmentationTypeFieldChange}
                />
              </Row>
              <Row>
                <Button disabled={jsonlContent && jsonlContent.length > 0 ? false: true} style={{ marginRight: '8px' }}>Upload Contents</Button>
                <Button className="sample-jsonl" onClick={handleSampleJsonOnClick}>Set Sample jsonl as per selected channel</Button>
              </Row>
            </Col>
          </Row>
        )
      case 'rdsquery':
        return (
          <div>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col3}>
                <div>Database <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col4}>
                <Select placeholder="Please Select" style={{ width: 320 }} onChange={handleDataBaseSelection}>
                  <Option value="postgres">POSTGRES</Option>
                  <Option value="mysql">MYSQL</Option>
                  <Option value="oracle">ORACLE</Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col3}>
                <div>Database Host <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col4}><Input placeholder="enter a name" /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col3}>
                <div>Database Name <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col4}><Input placeholder="enter a name" /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col3}>
                <div>Database Port <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col4}><Input placeholder="enter a name" /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col3}>
                <div>Database Username <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col4}><Input placeholder="enter a name" /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col3}>
                <div>Database password <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col4}><Input placeholder="enter a name" /></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col3}>
                <div>Database Query <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col4}><TextArea placeholder="select * from users;" /></Col>
            </Row>
            <Row>
              <Button style={{ marginRight: '8px' }}>Set Sample RDS Query as per selected channel</Button>
            </Row>
          </div>
        )
      case 'csvfile':
        return (
          <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Upload {...uploadProps} onChange={onSegmentationCsvFileUpload}>
              <Button icon={<UploadOutlined />}>Choose Files</Button>
            </Upload>
          </Row>
        )
    }
  }
  console.log("editData==>>", editData);
  console.log('newCampaignCsvContentFile', newCampaignCsvContentFile);
  console.log('newCampaignExistingTemplateId', newCampaignExistingTemplateId);
  console.log('uploadedZipFileData', uploadedZipFileData);
  console.log('campaignContentSelectOptions', campaignContentSelectOptions);
  return (
    <div className='subheader-container'>
      <div className="subheader-row">
        <div className="campaign-schedule-type-col">
          <section>Camapaign Schedule Type</section>
          <Select defaultValue="Onetime" style={{ width: 120 }} onChange={handleCampaignScheduleTypeChange}>
            <Option value="Onetime">Onetime</Option>
            <Option value="Recurring">Recurring</Option>
          </Select>
        </div>
        <div className="channel-col">
        <section>Channel</section>
          <Select defaultValue={campaignChannelType} style={{ width: 120 }} onChange={handleCampaignChannelChange}>
            <Option value="ALL">All</Option>
            <Option value="SMS">SMS</Option>
            <Option value="EMAIL">EMAIL</Option>
            <Option value="IAN">IAN</Option>
          </Select>
        </div>
        <div className="status-col">
          <section>Status</section>
          <Select defaultValue="ALL" style={{ width: 120 }} onChange={handleCampaignStatusChange}>
            <Option value="ALL">All</Option>
            <Option value="Created">Created</Option>
            <Option value="Cancelled">Cancelled</Option>
            <Option value="Completed">Completed</Option>
            <Option value="ReachedScheduledWindow">ReachedSchedulingWindow</Option>
            <Option value="ProcessBatches">ProcessBatches</Option>
            <Option value="ReadyToExecute">ReadyToExecute</Option>
          </Select>
        </div>
        <div className="history-col">
          <Checkbox onChange={handleHistoryChange} defaultChecked={showHistory} >History</Checkbox>
        </div>
        <div className="create-new-campaign">
          <Button type="primary" onClick={onCreateNewCampaignClick}>Create a New Campaign</Button>
        </div>
        <Modal
          title="Adding Campaign"
          centered
          visible={visible}
          onOk={() => onSaveCampaign()}
          onCancel={() => setVisible(false)}
          width={1000}
          okText="Save Campaign"
        >
          <div>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Name <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter name" data-name="campaignName" value={newCampaignName} onChange={onNewCampaignDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Description</div>
              </Col>
              <Col span={col2}><Input placeholder="enter description" data-name="campaignDescription" value={newCampaignDescription} onChange={onNewCampaignDataChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Subject</div>
              </Col>
              <Col span={col2}><Input placeholder="enter subject" data-name="campaignSubject" value={newCampaignSubject} onChange={onNewCampaignDataChange}/></Col>
            </Row>
            <Row className="user-email-container" style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div style={{ display: 'flex', alignItems: 'center' }}>From <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '8px' }}><InfoCircleFilled className="custom-domain-icon" onClick={onFromUserIconClick}/></span></div>
              </Col>
              <Col span={col2}><Input placeholder="enter user email" data-name="campaignUser" value={newCampaignUser} onChange={onNewCampaignDataChange}/></Col>
            </Row>
            {showFromUserText && (
            <Row className="custom-domain-whitelist-text" style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}></Col>
              <Col span={col2}> <Alert message="It is the domain of your sender email from where your emails will be sent. you have two choices, either use the custom domain or our sub-domain. If you decide to use a customized domain, use <from>@yourdomain.com. You will need to whitelist your domain with the token we supply.If you choose to send from our subdomain, just use the e-mail address of the <from>@eiskot.com, in which case no whitelist is required. Please allow 24 hours, before sending emails, it takes up to 24 hours to white list domains." type="info" /></Col>
            </Row>
            )}
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Test Mode</div>
              </Col>
              <Col span={col2}><Checkbox checked={newCampaignTestMode} data-name="campaignTestMode" value={newCampaignTestMode} onChange={onNewCampaignTestModeChange}/></Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Channel <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}>
                <Select defaultValue={channelType} style={{ width: 120 }} onChange={handleChannelChange}>
                  <Option value="SMS">SMS</Option>
                  <Option value="EMAIL">Email</Option>
                  <Option value="IAN">IAN</Option>
                  <Option value="WHATSAPP">WhatsApp</Option>
                  <Option value="VOICEMAIL">Voice Mail</Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Campaign Contents <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}>
                <Collapse onChange={onClickCampaignContents}>
                  <Panel header="Show More" key="1">     
                        <div style={{textAlign: "right"}}>
                          <a href={"/DesignStudio"+(editedCampaignId ? "/"+editedCampaignId : "")} 
                          target="_blank">{editedCampaignId ? "Open Existing HTML in Design Studio (in new window)":"Open Design Studio (in new window)"}</a>
                        </div>
                        <div className="file-upload-container">
                          <input type="file" name="file" onChange={onCampaignContentCsvFileUpload} />
                        </div>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Schedule <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}>
                <Collapse onChange={onClickSchedule}>
                  <Panel header="Show More" key="1">
                    <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <Col span="10">
                        <div>Schedule Type <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
                      </Col>
                      <Col span="14">
                        <Select defaultValue="onetime" style={{ width: 120 }} onChange={handleChange}>
                          <Option value="onetime">Onetime</Option>
                          <Option value="recurring">Recurring</Option>
                        </Select>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <Col span="10">
                        <div>Select Schduled Time <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
                      </Col>
                      <Col span="14">
                        <DatePicker defaultValue={moment()} /> <TimePicker onChange={onScheduledTimePickerChange} defaultValue={moment('00:00:00', 'HH:mm:ss')} />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Segmentation <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}>
                <Collapse onChange={onClickSegmentation}>
                  <Panel header="Show More" key="1">
                    <Tabs defaultActiveKey="1" onChange={onSegmentationTabChange}>
                      <TabPane tab="Add Segmentation" key="1">
                        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          <Col span="6">
                            <div>Segmentation Type <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
                          </Col>
                          <Col span="18">
                            <Select placeholder="Please Select" style={{ width: 160 }} onChange={handleSegmentationTypeChange}>
                              <Option value="jsonlfile">jsonlfile</Option>
                              <Option value="rdsquery">rdsquery</Option>
                              <Option value="csvfile">csvfile</Option>
                              <Option value="eventdriven">eventdriven</Option>
                              <Option value="api">api</Option>
                            </Select>
                          </Col>
                        </Row>
                        {segmentationType.length > 0 &&
                            renderSegmentationTypeSwitch(segmentationType)
                        }
                        
                      </TabPane>
                      <TabPane tab="Existing Trigger" key="2">
                        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          <Col span={col3}>
                            <div>Existing Segments <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
                          </Col>
                          <Col span={col4}>
                            <Select placeholder="Please Select" style={{ width: 320 }} onChange={handleExistingTriggerSelection}>
                              {segmentationSources && segmentationSources.data && segmentationSources.data.length >0  && 
                                segmentationSources.data.map((ss) => {
                                 return <Option key={ss.id} value={ss.id}>{ss.name} - Type: {ss.type}</Option>
                                })
                              }
                            </Select>
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            {/* <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Col span={col1}>
                <div>Campaign Attributes <span style={{ color: 'red', margin: '2px' }}>&#42;</span></div>
              </Col>
              <Col span={col2}>
                <Collapse onChange={onClickCampaignAttributes}>
                  <Panel header="Show More" key="1">
                    <Tabs defaultActiveKey="1" onChange={onCampaignAttributesTabChanges}>
                      <TabPane tab="Tab 1" key="1">
                        Content of Tab Pane 1
                      </TabPane>
                      <TabPane tab="Tab 2" key="2">
                        Content of Tab Pane 2
                      </TabPane>
                      <TabPane tab="Tab 3" key="3">
                        Content of Tab Pane 3
                      </TabPane>
                    </Tabs>
                  </Panel>
                </Collapse>
              </Col>
            </Row> */}
          </div>
        </Modal>
        <div className="search-container">
          <Search placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} enterButton />
        </div>
      </div>
    </div>
  )
}

Subheader.propTypes = {
  dispatch: PropTypes.func,
  contentData: PropTypes.object,
  segmentationSources: PropTypes.object,
  editData: PropTypes.object,
  reload: PropTypes.func,
  saveCampaignData: PropTypes.object
}

function mapStateToProps(state){
	return {
    contentLoading: state.getContent.loading,
    contentError: state.getContent.error,
    contentData: state.getContent.items,
    segmentationSources: state.getSegmentationSources.items,
    saveCampaignData: state.saveCampaign.items
	}
}

export default connect(mapStateToProps)(Subheader);