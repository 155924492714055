import React from 'react';
import MyEnhancedFormConnect from './EnhancedForm';

const SMSCampaign = () => (
  <div className="sms-campaign-container container">
    <h1>SMS Campaign</h1>
    <MyEnhancedFormConnect typeOfCampaign={'sms'}/>
  </div>
);

export default SMSCampaign;