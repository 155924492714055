import React, {Component} from "react";
import PropTypes from 'prop-types';
import Navigation from './CommonComponents/Navigation';
import Routes from "./Routes";
import { Auth } from 'aws-amplify';
import { updateJwtTokenInLocalStorage } from '../actions/genericActions';
import { connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { AuthState } from '@aws-amplify/ui-components'

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state={
      user: null,
      token: null
    }
  }
  // componentDidMount(){
  //   console.log("currentAuthenticatedUser PROPS==>> ", this.props);
  //     if(this.props.authState === AuthState.SignedIn && this.props.userData) {
  //       this.props.dispatch(updateJwtTokenInLocalStorage(this.props.userData.signInUserSession.accessToken.jwtToken));
  //     }
  // }
  render() {
    return (
      <div className="App mb-5">
        {/* <Header/> */}
        <Navigation />
        <Routes/>
        {/* <Footer/> */}
      </div>
    );
  }
}

App.propTypes = {
  authState: PropTypes.string
};

function mapStateToProps(state){
	return {
    items: state.generic
	}
}

export default connect(mapStateToProps)(App);