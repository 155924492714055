var Config = {
	REST_URL: {
		segmentationConfigs:"https://api.eiskot.com/np/v1/campaign/v1/segmentationconfigs",
		segmentationJsonlUpload:"https://api.eiskot.com/np/v1/campaign/v1/files/",
		campaignCrud:"https://api.eiskot.com/np/v1/campaign/campaign",
		contentCrud:"https://api.eiskot.com/np/v1/content",
		segmentationCrud:"https://api.eiskot.com/np/v1/segmentation",
		segmentationSources:"https://api.eiskot.com/np/v1/segmentationsources",
		scheduleCampaign:"https://api.eiskot.com/np/v1/campaign/schedule",
		filterCampaigns:"https://api.eiskot.com/np/v1/campaign/filtercampaigns",
		cancelCampaign:"https://api.eiskot.com/np/v1/campaign/cancel",
		segmentationCount:"https://api.eiskot.com/np/v1/campaign/v1/segmentationcount",
		getCampaignContentReferences: "https://api.eiskot.com/np/v1/campaign/campaigncontentrefences",
		uploadInfo: "https://api.eiskot.com/np/v1/campaign/v1/uploadinfo",
		saveContentUrl: "https://api.eiskot.com/np/v1/campaign/v1/savecontenturl",
    //cancelCampaign:"https://api.eiskot.com/np/v1/campaign/cancel",	
	},
	serverAPIKey:"XHqu4CUrpw5ObdmVblbYx1yq0spGzzmq8RlTxAx8",
	fromEmailAddress:"reply@eiskot.com",
	replyToEmailAddress:"reply@eiskot.com"
};

export default Config;