import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Tables from './CampaignsTable.jsx';
import Subheader from './Subheader';
import H1 from '../CommonComponents/H1';
import {fetchCampaignDataUsingAxios} from '../../actions/filterCampaignActions';

function CampaignGrid({dispatch}){
  const [editItemData, setEditItemData] = useState();
  const [reload, setReload] = useState(true);

  useEffect(()=>{
    dispatch(fetchCampaignDataUsingAxios())
  },[reload])

  function handleEditCampaign(e){
    setEditItemData(e);
  }

  function handleReload(){
    setReload(!reload);
  }

  return (
    <div className='campaign-grid-container'>
          <H1>Campaigns</H1>
          <hr/>
          <Subheader editData={editItemData} reload={handleReload}/>
          <Tables rowsPerPage ={10} onEditCampaign={handleEditCampaign}/>
    </div>
  )
}

CampaignGrid.propTypes = {
  dispatch: PropTypes.func
}
export default connect()(CampaignGrid);

