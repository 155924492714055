import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import 'antd/dist/antd.css';
import './styles/main.scss';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

const store = configureStore();
window.jQuery = window.$ = $;

Sentry.init({dsn: "https://96db6c8d6acf408a8f5af5f6d7eb5fbd@sentry.io/1854495"});
 
render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}

serviceWorker.unregister();