import axios from 'axios';
import * as service from './serviceUrls';
import { Auth } from 'aws-amplify';
import serviceUrls from '../config'

let headers = {
  headers: {
    "x-cs-carrierid":"generic",
    "x-cs-devid":"HTKM4WUQ31GWDHEM",
    "x-cs-apikey":"CaFcHfMhPkSpUrWuZw3z6B8DbGdJfNjQmSqVsXv2",
    "Content-Type": "text/plain",
    "authtoken":"abcd",
  }
};

export async function getJwtToken () {
  const currentSession = await Auth.currentSession();
  const providerId = currentSession.getIdToken().payload.sub;
  const identityJwt = currentSession.getIdToken().getJwtToken();
  return identityJwt;
}

const getCampaignData = (props) => {
  headers = {
    headers: {
      "Content-Type": "application/json",
      "x-cs-apikey":"CaFcHfMhPkSpUrWuZw3z6B8DbGdJfNjQmSqVsXv2",
      "x-cs-devid":"HTKM4WUQ31GWDHEM",
      "x-cs-carrierid":"generic" ,
      "accept": "application/json"
    }
  }
  let postPayload = {
      "scheduleType": props ? props.campaignScheduleType : "Onetime",
      "source":  props ? props.source : null,
      "masterStatus": props ? props.masterStatus : null,
      "status": props ? props.campaignStatus == "ALL" ? null : props.campaignStatus : null,
      "channel": props ? props.campaignChannelType == "ALL" ? null : props.campaignChannelType : null,
      "carrier": props ? props.carrier : null,
      "fetchHistory": props ? props.showHistory : "false"
    }
    console.log("postPayload", postPayload)
    return getJwtToken().then((value) => { 
      return axios
        .post(serviceUrls.REST_URL.filterCampaigns, postPayload, {...headers, headers: { ...headers.headers, 'x-access-token': value}})
        .then(function(response){
          return response;
        })
        .catch(error => {
          throw error;
        });
    })
    
};

const createCsvContent = (props) => {
  headers = {
    headers: {
      "Content-Type": "application/json",
      "x-cs-apikey":"CaFcHfMhPkSpUrWuZw3z6B8DbGdJfNjQmSqVsXv2",
      "x-cs-devid":"HTKM4WUQ31GWDHEM",
      "x-cs-carrierid":"generic" ,
      "accept": "application/json"
    }
  }
  let postPayload = {
    "scheduleType": props ? props.campaignScheduleType : "Onetime",
    "source":  props ? props.source : null,
    "masterStatus": props ? props.masterStatus : null,
    "status": props ? props.campaignStatus == "ALL" ? null : props.campaignStatus : null,
    "channel": props ? props.campaignChannelType == "ALL" ? null : props.campaignChannelType : null,
    "carrier": props ? props.carrier : null,
    "fetchHistory": props ? props.showHistory : "false"
  }
  console.log("postPayload", postPayload)
  return getJwtToken().then((value) => { 
    return axios
      .post(serviceUrls.REST_URL.contentCrud, postPayload, {...headers, headers: { ...headers.headers, 'x-access-token': value}})
      .then(function(response){
        return response;
      })
      .catch(error => {
        throw error;
      });
  })
    
};

const createNewCampaign = (payload)=>{
  /* eslint-disable */
  console.log("payload", payload)
  /* eslint-enable */
  let bodyFormData = new FormData();
  let jsonData = {
    "channel": payload.typeOfCampaign.toUpperCase(),
    "carrier": "generic",
    "scheduleType": "ONETIME",
    "campaignType": payload.campaignType.value.toUpperCase(),
    "source": "experiences",
    "name": payload.campaignName,
    "description": payload.description,
    "abTestCondition": null,
    "testMode": true,
    "properties": {
        "businessContentId": "test",
        "programId": "test"
    },
    "payload": {
        "body": "TEST"
    },
    "scheduledTime": 1569332718814,
    "schedule": {
        "timezoneDefault": "PST",
        "timezonesSelected": [
            "CST",
            "EST",
            "MST",
            "PST"
        ]
    },
    "updatedBy": "Deepika",
    "segmentation": {
        "type": "jsonlfile",
        "jsonlfile": {
            "s3Key": "test.csv",
            "s3Region": "us-east-1"
        }
    }
  };
  headers.headers['Content-Type'] =  'multipart/form-data';
  bodyFormData.append('json_data', JSON.stringify(jsonData));
  bodyFormData.append('segmentation_file', payload.segmentFile);
  bodyFormData.append('content_file', payload.contentFile);

    return axios
    .post(`${service.newCampaignCreationUrl}`, bodyFormData, headers)
      .then(function (response) {
          //handle success
          return response;
      })
      .catch(function (response) {
          //handle error
          return response;
      });
    /* eslint-enable*/
}

const getContent = () => {
  console.log("getContent")
  headers = {
    headers: {
      "Content-Type": "application/json",
      "x-cs-apikey":"CaFcHfMhPkSpUrWuZw3z6B8DbGdJfNjQmSqVsXv2",
      "x-cs-devid":"HTKM4WUQ31GWDHEM",
      "x-cs-carrierid":"generic" ,
      "accept": "application/json"
    }
  }
  console.log("serviceUrls.REST_URL.getContent", serviceUrls.REST_URL.getContent)
    return getJwtToken().then((value) => { 
      return axios
        .get(serviceUrls.REST_URL.contentCrud, {...headers, headers: { ...headers.headers, 'x-access-token': value}})
        .then(function(response){
          console.log("response content data")
          return response;
        })
        .catch(error => {
          throw error;
        });
    })
    
};

const getSegmentationSources = () => {
  headers = {
    headers: {
      "Content-Type": "application/json",
      "x-cs-apikey":"CaFcHfMhPkSpUrWuZw3z6B8DbGdJfNjQmSqVsXv2",
      "x-cs-devid":"HTKM4WUQ31GWDHEM",
      "x-cs-carrierid":"generic" ,
      "accept": "application/json"
    }
  }
  console.log("serviceUrls.REST_URL.getContent", serviceUrls.REST_URL.getContent)
    return getJwtToken().then((value) => { 
      return axios
        .get(serviceUrls.REST_URL.segmentationSources, {...headers, headers: { ...headers.headers, 'x-access-token': value}})
        .then(function(response){
          console.log("response content data")
          return response;
        })
        .catch(error => {
          throw error;
        });
    })
};

const saveCampaign = (props) => {
  console.log("campgin prps service", props)
  let campaignData = {...props}
  let formData = new FormData();
  formData.append('json_data',JSON.stringify(props));
  if(props.csvfile){
    formData.append("segmentation_file", props.csvfile);
  }  
  formData.append("content_file", props.contentfile);

  headers = {
    headers: {
      "Content-Type": "application/json",
      "x-cs-apikey":"CaFcHfMhPkSpUrWuZw3z6B8DbGdJfNjQmSqVsXv2",
      "x-cs-devid":"HTKM4WUQ31GWDHEM",
      "x-cs-carrierid":"generic" ,
      "accept": "application/json"
    }
  }
  return getJwtToken().then((value) => { 
    return axios
      .post(serviceUrls.REST_URL.campaignCrud, formData, {...headers, headers: { ...headers.headers, 'x-access-token': value}})
      .then(function(response){
        console.log("response content data")
        return response;
      })
      .catch(error => {
        throw error;
      });
  })
};

const deleteCampaign = (props) => {
  // console.log("props", props)
  // let campaignData = {...props}
  // console.log("delete campaignData", campaignData);
  let formData = new FormData();
  formData.append('campaignId', props.id);
  headers = {
    headers: {
      "Content-Type": "application/json",
      "x-cs-apikey":"CaFcHfMhPkSpUrWuZw3z6B8DbGdJfNjQmSqVsXv2",
      "x-cs-devid":"HTKM4WUQ31GWDHEM",
      "x-cs-carrierid":"generic" ,
      "accept": "application/json"
    }
  }
  return getJwtToken().then((value) => { 
    return axios
      .post(serviceUrls.REST_URL.cancelCampaign, {...headers, headers: { ...headers.headers, 'x-access-token': value}})
      .then(function(response){
        // console.log("response content data")
        return response;
      })
      .catch(error => {
        throw error;
      });
  })
};

export {
  getCampaignData,
  createNewCampaign,
  getContent,
  getSegmentationSources,
  saveCampaign,
  deleteCampaign
};
