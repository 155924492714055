import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import filterCampaignReducer from './filterCampaignReducer';
import createNewCampaignReducer from './createNewCampaignReducer';
import getContentReducer from './getContentReducer'
import genericReducer from './genericReducer';
import getSegmentationSourcesReducer from './getSegmentationSourcesReducer'
import saveCampaignReducer from './saveCampaignReducer'
import authReducer from './authReducer'


export default (history) => combineReducers({
  router: connectRouter(history),
  filterCampaign : filterCampaignReducer,
  createNewCampaign: createNewCampaignReducer,
  generic: genericReducer,
  getContent: getContentReducer,
  getSegmentationSources: getSegmentationSourcesReducer,
  saveCampaign: saveCampaignReducer,
  authData: authReducer
});
