import * as C from '../constants';
import { getContent } from '../services';

export const fetchDataBegin = () => {
  return ({
    type: C.FETCH_CONTENT_BEGIN
  })
};

export const fetchDataSuccess = data  =>{
  return ({
    type: C.FETCH_CONTENT_SUCCESS,
    payload: { 
      data 
    }
  })
};

export const fetchDataFailure = error => {
  return ({
    type: C.FETCH_CONTENT_FAILURE,
    payload: { error }
  })
};

export function fetchContentusingAxios() {
  return (dispatch) => {
      dispatch(fetchDataBegin());
      getContent()
        .then( response =>{
              dispatch(fetchDataSuccess(response))
          })
        .catch(error => {
          dispatch(fetchDataFailure(error));
        })
  }
}
