import * as C from '../constants';
import { getCampaignData } from '../services';

export const fetchDataBegin = () => {
  return ({
    type: C.FETCH_CAMPAIGN_DATA_BEGIN
  })
};

export const fetchDataSuccess = data  =>{
  return ({
    type: C.FETCH_CAMPAIGN_DATA_SUCCESS,
    payload: { 
      data 
    }
  })
};

export const fetchDataFailure = error => {
  return ({
    type: C.FETCH_CAMPAIGN_DATA_FAILURE,
    payload: { error }
  })
};

export function fetchCampaignDataUsingAxios(props) {
    return (dispatch) => {
        dispatch(fetchDataBegin());
        getCampaignData(props)
          .then( response =>{
                dispatch(fetchDataSuccess(response))
            })
          .catch(error => {
            dispatch(fetchDataFailure(error));
          })
    }
}
