import React from 'react';
// import CampaignSubHeader from './CampaignSubHeader';
import CampaignGrid from './CampaignGrid';

const Campaigns = () => {
  return (
    <div>
      <div className="container">
        {/* <CampaignSubHeader/> */}
        <CampaignGrid />
      </div>
    </div>
  );
};

export default Campaigns;
