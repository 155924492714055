import * as C from '../constants';
import { deleteCampaign } from '../services';

export const fetchDataBegin = () => {
  return ({
    type: C.DELETE_CAMPAIGN_BEGIN
  })
};

export const fetchDataSuccess = data  =>{
  return ({
    type: C.DELETE_CAMPAIGN_SUCCESS,
    payload: { 
      data 
    }
  })
};

export const fetchDataFailure = error => {
  return ({
    type: C.DELETE_CAMPAIGN_FAILURE,
    payload: { error }
  })
};

export function deleteCampaignUsingAxios(props, prop) {
    return (dispatch) => {
        dispatch(fetchDataBegin());
        deleteCampaign(props)
          .then( response =>{
                prop.history.push('/Campaigns');
                dispatch(fetchDataSuccess(response))
            })
          .catch(error => {
            dispatch(fetchDataFailure(error));
          })
    }
}
