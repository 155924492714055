/* eslint-disable */
import React from 'react';
import Select from "react-select";

const options = [
  { value: "Food", label: "Food" },
  { value: "Being Fabulous", label: "Being Fabulous" },
  { value: "Ken Wheeler", label: "Ken Wheeler" },
  { value: "ReasonML", label: "ReasonML" },
  { value: "Unicorns", label: "Unicorns" },
  { value: "Kittens", label: "Kittens" }
];

class MySelect extends React.Component {
  constructor(props){
    super(props)
  }
  handleChange = (value, id) => {
    console.log("this.props handleChange", this.props);
    // this is going to call setFieldValue and manually update values.topics
    switch(this.props.tag){
      // case "testMode":
      //   this.props.onChange("testMode", value);
      //   break;
      case "campaignType":
        this.props.onChange("campaignType", value);
        break;
    }    
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    switch(this.props.tag){
      // case "targetCriteria":
      //   this.props.onBlur("targetCriteria", true);
      //   break;
      case "campaignType":
        this.props.onBlur("campaignType", true);
        break;
    }      
  };

  render() {
    return (
      <div style={{ margin: "1rem 0" }}>
        <label htmlFor="color" className="required">{this.props.label}</label>
        <Select
          id="color"
          options={this.props.options || options}
          // isMulti
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
        />
        {!!this.props.error && this.props.touched && (
          <div style={{ color: "red", marginTop: ".5rem" }}>
            {this.props.error}
          </div>
        )}
      </div>
    );
  }
}

export default MySelect;