import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';

function NewUser(){
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button type="button" className="btn modal-toggle-button" onClick={handleShow}>
        New user
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className='new-user-form-container'>
          <label>
            <span>Name</span>
            <input
              name="name"
              type="text" 
            />
          </label>
          <label>
            <span>Email</span>
            <input
              name="email"
              type="email" 
            />
          </label>
          <label>
            <span className='pr-2'>Role</span>
            <select name="role" id="role">
              <option>Admin</option>
              <option>Read</option>
              <option>Read/Write</option>
            </select>
          </label>
          <textarea disabled value=" Note: Password will be auto generated and send to email"/>
        </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewUser;