export const FETCH_CAMPAIGN_DATA_BEGIN = "FETCH_CAMPAIGN_DATA_BEGIN";
export const FETCH_CAMPAIGN_DATA_SUCCESS = "FETCH_CAMPAIGN_DATA_SUCCESS";
export const FETCH_CAMPAIGN_DATA_FAILURE = "FETCH_CAMPAIGN_DATA_FAILURE";

export const CREATE_NEW_CAMPAIGN_BEGIN = "CREATE_NEW_CAMPAIGN_BEGIN";
export const CREATE_NEW_CAMPAIGN_SUCCESS = "CREATE_NEW_CAMPAIGN_SUCCESS";
export const CREATE_NEW_CAMPAIGN_FAILURE = "CREATE_NEW_CAMPAIGN_FAILURE";

export const DELETE_CAMPAIGN_BEGIN = "DELETE_CAMPAIGN_BEGIN";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

export const FETCH_CONTENT_BEGIN = "FETCH_CONTENT_BEGIN";
export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS";
export const FETCH_CONTENT_FAILURE = "FETCH_CONTENT_FAILURE";

export const FETCH_SEGMENTATION_SOURCES_BEGIN = "FETCH_SEGMENTATION_SOURCES_BEGIN";
export const FETCH_SEGMENTATION_SOURCES_SUCCESS = "FETCH_SEGMENTATION_SOURCES_SUCCESS";
export const FETCH_SEGMENTATION_SOURCES_FAILURE = "FETCH_SEGMENTATION_SOURCES_FAILURE";

export const SAVE_CAMPAIGN_BEGIN = "SAVE_CAMPAIGN_BEGIN";
export const SAVE_CAMPAIGN_SUCCESS = "SAVE_CAMPAIGN_SUCCESS";
export const SAVE_CAMPAIGN_FAILURE = "SAVE_CAMPAIGN_FAILURE";

//Analytics events
export const PAGE_EVENT = 'page';
export const TRACK_EVENT = 'track';
export const CAMPAIGN_MANAGER_PORTAL_LOADED = 'Campaign Manager Portal Loaded';


export const UPDATE_JWT_TOKEN_IN_LOCAL_STORAGE = "UPDATE_JWT_TOKEN_IN_LOCAL_STORAGE";

export const SAVE_AUTH_DATA = "SAVE_AUTH_DATA";