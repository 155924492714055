import * as C from '../constants';
import { saveCampaign } from '../services';

export const fetchDataBegin = () => {
  return ({
    type: C.SAVE_CAMPAIGN_BEGIN
  })
};

export const fetchDataSuccess = data  =>{
  return ({
    type: C.SAVE_CAMPAIGN_SUCCESS,
    payload: { 
      data 
    }
  })
};

export const fetchDataFailure = error => {
  return ({
    type: C.SAVE_CAMPAIGN_FAILURE,
    payload: { error }
  })
};

export function saveCampaignUsingAxios(props) {
  console.log("here action")
  return (dispatch) => {
      dispatch(fetchDataBegin());
      saveCampaign(props)
        .then( response =>{
              dispatch(fetchDataSuccess(response))
          })
        .catch(error => {
          dispatch(fetchDataFailure(error));
        })
  }
}
