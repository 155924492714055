import * as C from '../constants';

export const saveAuthDataSuccess = (data) => {
  console.log("saveAuthData==>>", data)
    return ({
        type: C.SAVE_AUTH_DATA,
        payload: { 
          data 
        }
      })
}

export function saveAuthData(data) {
    return (dispatch) => {
        dispatch(saveAuthDataSuccess(data));
    }
}
