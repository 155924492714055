import React from 'react'
import { post } from 'axios';
import { encode as base64_encode} from 'base-64';

const default_state = {
  file:null,
  response : null,
  customParams : base64_encode('{"Some Key":"Some Value"}'),
  id: null
}

class UploadZip extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = default_state;
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this);
    this.submitCustomParams = this.submitCustomParams.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  onFormSubmit(e){
    e.preventDefault() // Stop form submit
    let { onUploadZipSuccessful } = this.props;
    this.submitCustomParams().then((response)=> {
      this.setState({id:response.data.id});
      this.fileUpload(this.state.file, response.data.id).then((response)=>{
        console.log(response.data);
        this.setState({response: response.data})
        onUploadZipSuccessful(response.data);
      });
    })
    
  }
  onChange(e) {
    this.setState({file:e.target.files[0]})
  }

  handleReset() {
    this.props.handleUploadZipReset();
    if (document.getElementById("customParams")) {
      document.getElementById("customParams").value = "";
    }
    this.setState(default_state);
  }

  onTextChange(e) {
    this.setState({customParams:base64_encode(e.target.value)});
  }

  submitCustomParams(){
    const url = 'https://api.eiskot.com/customParams';
    const config = {
        headers: {
            'content-type': 'application/json',
        }
    }
    return  post(url, this.state.customParams,config)
  }

  fileUpload(file, id){
    const url = 'https://api.eiskot.com/uploadzip?id='+id;
    const formData = new FormData();
    formData.append('file',file);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }
    return  post(url, formData,config)
  }
  render() {
    let upload_id = this.state.response ? this.state.response.id : null;
    return (
      <>
      <a href="#" onClick={this.handleReset}>Reset</a>
      {this.state.response == null?
          <div style={{marginLeft: "20px"}}>
            <form onSubmit={this.onFormSubmit}>
              <h1>Create Template</h1>
              <div>Custom Template Parameters (JSON Map Format)</div>
              <div>
              <textarea onChange={this.onTextChange} placeholder={"{\"some key\":\"some value\"}"} name="customParams" id="customParams" rows={10} cols={100} style={{marginBottom:"50px"}}
              ></textarea>
              
              </div>
              <input type="file" onChange={this.onChange} />
              <div>
              <button type="submit">Upload .zip file</button>
              </div>
            </form>
          </div>
      :
        <div id ="templateFiles">
            {this.state.response.paths.map(function(item, i){
                return <div key={item.file}>
                  <input type="button" value="Select File"
                      onClick={(e)=>{alert(`${upload_id}/${item.file}`)}}
                    >
                    
                  </input>
                <div style={{marginLeft: "20px", overflow: "auto"}}><iframe height="600px" width="1000px" src={item.url} title="description"></iframe></div>
              </div>;
            })
            }
        </div>
      }
      </>
    )
  }
}

export default UploadZip;