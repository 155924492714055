import * as C from '../constants';

const initialState = {
  loginState: {
    jwtToken: null
  }
};
const genericReducer = (state = initialState.loginState, action) => {
    switch (action.type) {
        case C.UPDATE_JWT_TOKEN_IN_LOCAL_STORAGE:
          console.log("UPDATE_JWT_TOKEN_IN_LOCAL_STORAGE===>>", action);
          localStorage.setItem("JWT_TOKEN", action.payload.token);
          return Object.assign({}, state, {
            jwtToken: action.payload.token
          });
        default:
            return state;
    }
}

export default genericReducer;