import * as C from '../constants';

export function updateJwtTokenInLocalStorage(value){
  return (dispatch) => {
    dispatch(updateJwt(value));
  }
}

export const updateJwt = (token) => {
  console.log("updateJwt===>>>", token);
  return ({
    type: C.UPDATE_JWT_TOKEN_IN_LOCAL_STORAGE, 
    payload: { token }
  })
}