import React from 'react';
import PropTypes from 'prop-types';

export default function IMG({size, width, height, enableClick, ...rest}){
  if (size) {
    width = 1.5 * size + 'px';
    height = 1.5 * size + 'px';
  }
  function onImgClick(){
    if(enableClick){
      this.props.onImgClick();
    }
  }
  return (
    <img onClick={()=>onImgClick()} width={width} height={height} {...rest}/>
  )
}

IMG.propTypes = {
  onImgClick : PropTypes.func,
  size : PropTypes.number,
  width : PropTypes.string,
  height : PropTypes.string,
  enableClick : PropTypes.bool
}