import React, {Component} from 'react';
import H1 from '../CommonComponents/H1';
import CampaignType from '../CommonComponents/CampaignType';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import SMSIcon from '../../img/SMS.jpg';
import EmailIcon from '../../img/Email.jpg';
// import CampaignManagerEmailIcon from '../../img/CampaignManagerEmail.jpg';
// import CampaignManagerSMSIcon from '../../img/CampaignManagerSMS.jpg';

class NewCampaign extends Component{
  constructor(props){
    super(props);
    this.onClickEmailCampaign = this.onClickEmailCampaign.bind(this);
    this.onClickSMSCampaign = this.onClickSMSCampaign.bind(this);
  }
  onClickEmailCampaign(){
    this.props.history.push('/Campaigns/EmailCampaign')
  }
  onClickSMSCampaign(){
    this.props.history.push('/Campaigns/SMSCampaign')
  }
  render(){
    return (
      <div className='new-campaign-container container'>
        <H1>New Campaign</H1>
        <p><b>Select the type of Campaign you would like to create</b></p>
        {/* <div className="row">
          <div className="col campaign-div" onClick={this.onClickEmailCascadeCampaign}>
            <CampaignType type="Email cascading campaign" icon={CampaignManagerEmailIcon}/>
          </div>
          <div className="col campaign-div" onClick={this.onClickEmailCascadeCampaign}>
            <CampaignType type="SMS cascading campaign" icon={CampaignManagerSMSIcon}/>
          </div>
        </div> */}
        <div className="row">
          <div className="col campaign-div" onClick={this.onClickSMSCampaign}>
            <CampaignType type="SMS Campaign" icon={SMSIcon}/>
          </div>
          <div className="col campaign-div" onClick={this.onClickEmailCampaign}>
            <CampaignType type="Email Campaign" icon={EmailIcon}/>
          </div>
        </div>
      </div>
    )
  }
}

NewCampaign.propTypes = {
  history: PropTypes.object
}

export default withRouter(NewCampaign);