import React from 'react';
import MyEnhancedFormConnect from './EnhancedForm';

const EmailCampaign = () => (
  <div className="email-campaign-container container">
    <h1>Email Campaign</h1>
    <MyEnhancedFormConnect typeOfCampaign={'email'}/>
  </div>
);

export default EmailCampaign;