import React, {Component} from "react";
import config from '../aws-exports';
import App from "./App";
import { AmplifySignOut, withAuthenticator, AmplifyAuthenticator, AmplifySignIn , AmplifySignUp} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { Auth, Amplify } from 'aws-amplify';
import { saveAuthData } from '../actions/saveAuthActions';
import { connect } from "react-redux";

Amplify.configure(config);
Auth.configure(config);

class LandingPage extends Component {

  componentDidMount() {
    var self = this;
    console.log("did mount", self.props);
    onAuthUIStateChange((nextAuthState, data) => {
      let authData = { "authState" : nextAuthState, "userData" : data};
      self.props.dispatch(saveAuthData(authData));
    })
  }

  render() {   
    console.log("this props authdsta", this.props.items);
    console.log("this.props.items AUTH STATE ===>>>", this.props.items.authState);
    if(this.props.items.authState === AuthState.SignedIn && this.props.items.userData) {
      console.log("AuthState.SignedIn ==>>>")
      return (
        <>
          <App {...this.props.items}/>
        </>
      )
    } else {
      console.log("AuthState SIGNUP PAGE ==>>>")
      return (
        <>
          <AmplifyAuthenticator>
            {/* don't include phone number for signup */}
            <AmplifySignUp
              slot="sign-up"
              formFields={[
                { type: 'username' },
                { type: 'email' },
                { type: 'password' },
              ]}
            />
          </AmplifyAuthenticator>
        </>
      )
    } 

  }
}

function mapStateToProps(state){
	return {
    items: state.authData.items
	}
}

export default connect(mapStateToProps)(LandingPage);


    // return (
    //   <div>
    //     <AmplifyAuthenticator amplifyConfig={config}>
    //       <App {...this.props}/>
    //     </AmplifyAuthenticator>
    //   </div>
    // );