import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Pie} from 'react-chartjs-2';

const state = {
  labels:['Succeeded', 'Failed', 'N/A'],
  datasets: [
    {
      label: 'Rainfall',
      backgroundColor: [
        '#2FDE00',
        '#C9DE00',
        '#B21F00'
      ],
      hoverBackgroundColor: [
        '#175000',
        '#4B5000',
        '#501800'
      ],
      data: [80, 30, 50]
    }
  ]
}

class Analytics extends React.Component {
  constructor(props){
    super(props);
  }
  handleClose(){
    this.props.showAnalytics(false)
  }
  render(){
    return (
      <div className='analytics-container'>
        <Modal show={this.props.show} onHide={(e)=> this.handleClose(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Analytics</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="campaign-info p-3">
            <div><strong>Campaign Start Date:</strong> 2019-07-07</div> 
            <div><strong>Campaign End Date:</strong> 2019-09-09</div> 
          </div>
          <Pie
            data={state}
            options={{
              title:{
                display:true,
                text:'Campaign Details',
                fontSize:20
              },
              legend:{
                display:true,
                position:'right'
              }
            }}
          />
          <button className="mixpanel-link">Live Link</button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(e)=> this.handleClose(e)}>
              Close
            </Button>
            <Button variant="primary" onClick={(e)=> this.handleClose(e)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

}

Analytics.propTypes = {
  showAnalytics: PropTypes.any,
  show: PropTypes.any
}

export default Analytics;